import React, { useContext } from 'react'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import {
  Block,
  Container,
  Layout,
  Breadcrumbs,
  SearchHitList
} from '@components/'
import SearchContext from '@context/searchContext'

const SearchResults = ({ pageContext }) => {
  const { searchState } = useContext(SearchContext)

  return (
    <Layout>
      <HelmetDatoCms defer={false}>
        <title>{'Search Results | WeCare Health'}</title>
        <meta
          property="og:title"
          content={'Search Results | WeCare Health'}
          key="title"
        />
        <meta
          property="twitter:title"
          content={'Search Results | WeCare Health'}
          key="twitter:title"
        />
      </HelmetDatoCms>
      <Block gutters>
        <Breadcrumbs breadcrumbs={pageContext?.breadcrumbs} />
      </Block>

      <Container maxWidth={'large'}>
        <Block gutters paddingTop={'single'} paddingBottom={'single'}>
          <h1>{'Search Results'}</h1>
        </Block>
        <SearchHitList query={searchState?.query} />
      </Container>
    </Layout>
  )
}

export default SearchResults
